import "jssocials";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons/faPaperPlane";

jQuery(document).ready(() => { 

    jQuery(".social-share").jsSocials({
        showLabel: false,
        showCount: false,
        shareIn: "popup",
        shares: [
          {
            share: "twitter",
            label: "Twitter",
            logo: "fa-brands fa-twitter",
          },
          {
            share: "linkedin",
            label: "LinkedIn",
            logo: "fa-brands fa-linkedin-in",
          },
          {
            share: "telegram",
            label: "Telegram",
            logo: "fa fa-paper-plane",
          },
          {
            share: "email",
            label: "Email",
            logo: "fa-solid fa-envelope",
          },
        ],
    });


})
    
    

library.add(
    faTwitter,
    faLinkedinIn,
    faEnvelope,
    faPaperPlane
);
dom.watch();